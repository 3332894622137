import React, { useState } from "react";
import "./newLeadDialogBox.css";
import { Contexts } from "../Context/Contexts";
import axios from "axios";

import Cou_States from "../Data/States";

export default function NewLeadDialog(props) {
  const new_lead_api = process.env.REACT_APP_API_N_LEAD;

  // const { newLeadDialogBox, setNewLeadDialogBox } = useContext(Contexts);
  const inputTag = document.getElementsByTagName("input");

  // Drop Down Menus

  const [leadStatusClass, setLeadstatusClass] = useState(
    "listDropDown_disabled"
  );

  const [machineReqClass, setMachineReqClass] = useState(
    "dropDownMachineReqDiv_disabled"
  );
  const [leadSourceClass, setLeadSourceClass] = useState(
    "dropDownList_LeadSource_disabled"
  );

  const [statePicklistClass, setStatePicklistClass] = useState(
    "state_picklist_newLead_disabled"
  );

  // End of Drop Down Menus

  // Post Data

  const [leadName, setLeadName] = useState("");
  const [mobile, setMobile] = useState("");
  const [leadSource, setLeadSource] = useState("None");
  const [leadStatus, setLeadStatus] = useState("None");
  const [date, setDate] = useState("");
  const [productReq, setProductReq] = useState("None");
  const [productSize, setProductSize] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("Select State");
  const [country, setCountry] = useState("India");
  const [pincode, setPincode] = useState("None");
  const [notes, setNotes] = useState("");

  // Post Data
  const handleSubmit = async (e) => {
    // window.open(`https://wa.me/91${mobile}?text=Hello,%20${leadName}`, "blank");

    if (
      leadName === "" ||
      mobile === "" ||
      leadSource === "" ||
      leadStatus === "" ||
      date === "" ||
      productReq === "" ||
      city === "" ||
      state === ""
    ) {
      alert("Something Went Wrong");
    } else {
      e.preventDefault();
      try {
        // for Production
        await axios.post(
          // "https://api.x360marketing.org/api/newlead",
          new_lead_api,
          {
            //
            // for Testing
            // await axios.post("http://127.0.0.1:3001/api/newlead", {
            //
            leadName,
            mobile,
            leadSource,
            leadStatus,
            date,
            productReq,
            productSize,
            city,
            state,
            country,
            pincode,
            notes,
          }
        );

        inputTag[0].value = "";
        inputTag[1].value = "";
        inputTag[2].value = "";
        inputTag[3].value = "";
        inputTag[4].value = "";
        inputTag[5].value = "";
        inputTag[6].value = "";
        inputTag[7].value = "";
        // inputTag[8].value = "";
        // inputTag[9].value = "";
        // inputTag[10].value = "";
        // inputTag[11].value = "";

        // alert("Lead saved successfully!");

        setLeadName("");
        setMobile("");
        setLeadSource("None");
        setLeadStatus("None");
        setDate("");
        setProductReq("None");
        setProductSize("");
        setCity("");
        setState("");
        setCountry("");
        setPincode("");
        setNotes("");
      } catch (error) {
        console.error("Error saving item:", error);
        // alert("Error saving item. Please try again later.");
        alert(error);
      }
      alert("Lead Saved Successfully");
      window.location.reload();
    }
  };

  // End of Post Data

  const { LeadsDialogBoxClass, LeadsSectionClass } = React.useContext(Contexts);

  const [newLeadDialogBox, setNewLeadDialogBox] = LeadsDialogBoxClass;

  // eslint-disable-next-line
  const [leadsSectionClass, setLeadsSectionClass] = LeadsSectionClass;

  return (
    <>
      <section id="dialogBoxNewLead" className={props.Class}>
        <div className="newLeadNavDiv">
          <h1>New Lead</h1>
          <i
            onClick={() => {
              if (newLeadDialogBox === "NewLeadDialogBox_Close") {
                setNewLeadDialogBox("NewLeadDialogBox_Open");
                setLeadsSectionClass("LeadsSection_disabled");
                console.log(newLeadDialogBox);

                return;
              } else {
                setNewLeadDialogBox("NewLeadDialogBox_Close");
                console.log(newLeadDialogBox);
                setLeadsSectionClass("LeadsSection");
                inputTag[0].value = "";
                inputTag[1].value = "";
                inputTag[2].value = "";
                inputTag[3].value = "";
                inputTag[4].value = "";
                inputTag[5].value = "";
                inputTag[6].value = "";
                inputTag[7].value = "";
                // inputTag[8].value = "";
                // inputTag[9].value = "";
                // inputTag[10].value = "";
                // inputTag[11].value = "";

                setLeadName("");
                setMobile("");
                setLeadSource("None");
                setLeadStatus("None");
                setDate("");
                setProductReq("None");
                setProductSize("");
                setCity("");
                setState("");
                setCountry("");
                setPincode("");
                setNotes("");

                return;
              }
            }}
            className="fa-solid fa-xmark fa-lg"
          ></i>
        </div>

        <div className="addLeadInfoDiv">
          <div className="leftDiv_AddLead">
            <label htmlFor="newLeadName">Name*</label>
            <input
              onChange={(e) => {
                setLeadName(e.target.value);
              }}
              placeholder="Enter Lead Name"
              type="text"
              className="newLead_Name"
            />{" "}
            <label htmlFor="newLeadName">Mobile* {"(What's App)"} </label>
            <input
              onChange={(e) => {
                setMobile(e.target.value);
              }}
              placeholder="Enter Mobile"
              type="text"
              className="newLead_Name"
            />{" "}
            <label htmlFor="newLeadName">Lead Status*</label>
            {/* <input
              onChange={(e) => {
                setLeadSource(e.target.value);
              }}
              placeholder="Enter Lead Source"
              type="text"
              className="newLead_Name"
            />{" "} */}
            <div
              onClick={() => {
                if (leadStatusClass === "listDropDown") {
                  setLeadstatusClass("listDropDown_disabled");
                } else {
                  setLeadstatusClass("listDropDown");
                }
              }}
              className="leadSource_dropDown"
            >
              <span>
                {leadStatus} <i className="fa-solid fa-caret-down"></i>
              </span>
              <div className={leadStatusClass}>
                <ul>
                  <li
                    onClick={() => {
                      setLeadStatus("Working - Not Contacted");
                    }}
                  >
                    Working - Not Contacted
                  </li>
                  <li
                    onClick={() => {
                      setLeadStatus("Open - Contacted");
                    }}
                  >
                    Open - Contacted
                  </li>
                  <li
                    onClick={() => {
                      setLeadStatus("Closed - Converted");
                    }}
                  >
                    Closed - Converted
                  </li>
                  <li
                    onClick={() => {
                      setLeadStatus("Closed - Not Converted");
                    }}
                  >
                    Closed - Not Converted
                  </li>
                </ul>
              </div>
            </div>
            <label htmlFor="newLeadName">Lead Creation Date</label>
            <input
              onChange={(e) => {
                setDate(e.target.value);
              }}
              placeholder="Enter What's App"
              type="date"
              className="newLead_Name"
            />{" "}
            <label htmlFor="newLeadName">Machine Requirement*</label>
            {/* <input
              onChange={(e) => {
                setProductReq(e.target.value);
              }}
              placeholder="Enter Machine Requirement"
              type="text"
              className="newLead_Name"
            />{" "} */}
            <div
              onClick={() => {
                if (machineReqClass === "dropDownMachineReqDiv_disabled") {
                  setMachineReqClass("dropDownMachineReqDiv");
                } else {
                  setMachineReqClass("dropDownMachineReqDiv_disabled");
                }
              }}
              className="dropdownMachineReq"
            >
              <span>
                {productReq} <i className="fa-solid fa-caret-down"></i>
              </span>

              <div className={machineReqClass}>
                <ul>
                  <li
                    onClick={() => {
                      setProductReq("Combined Planer");
                    }}
                  >
                    Combined Planer
                  </li>
                  <li
                    onClick={() => {
                      setProductReq("Surface Planer");
                    }}
                  >
                    Surface Planer
                  </li>{" "}
                  <li
                    onClick={() => {
                      setProductReq("Thickness Planer");
                    }}
                  >
                    Thickness Planer
                  </li>
                  <li
                    onClick={() => {
                      setProductReq("Chain Mortiser");
                    }}
                  >
                    Chain Mortiser
                  </li>
                  <li
                    onClick={() => {
                      setProductReq("Chisel Mortiser");
                    }}
                  >
                    Chisel Mortiser
                  </li>
                  <li
                    onClick={() => {
                      setProductReq("Vertical Band Saw");
                    }}
                  >
                    Vertical Band Saw
                  </li>
                  <li
                    onClick={() => {
                      setProductReq("Other");
                    }}
                  >
                    Other
                  </li>
                </ul>
              </div>
            </div>
            <label htmlFor="newLeadName">Machine Size</label>
            <input
              onChange={(e) => {
                setProductSize(e.target.value);
              }}
              placeholder="Enter Size"
              type="text"
              className="newLead_Name"
            />{" "}
            <label htmlFor="newLeadName">Lead Source*</label>
            {/* <input
              onChange={(e) => {
                setLeadSource(e.target.value);
              }}
              placeholder="Enter Lead Source"
              type="text"
              className="newLead_Name"
            />{" "} */}
            <div
              onClick={() => {
                if (leadSourceClass === "dropDownList_LeadSource_disabled") {
                  setLeadSourceClass("dropDownList_LeadSource");
                } else {
                  setLeadSourceClass("dropDownList_LeadSource_disabled");
                }
              }}
              className="dropDownMenu_LeadSource"
            >
              <span>
                {leadSource} <i className="fa-solid fa-caret-down"></i>
              </span>
              <div className={leadSourceClass}>
                <ul>
                  <li
                    onClick={() => {
                      setLeadSource("Indiamart");
                    }}
                  >
                    Indiamart
                  </li>
                  <li
                    onClick={() => {
                      setLeadSource("Meta");
                    }}
                  >
                    Meta
                  </li>
                  <li
                    onClick={() => {
                      setLeadSource("Google");
                    }}
                  >
                    Google
                  </li>
                  <li
                    onClick={() => {
                      setLeadSource("Direct Call");
                    }}
                  >
                    Direct Call
                  </li>
                  <li
                    onClick={() => {
                      setLeadSource("Reference");
                    }}
                  >
                    Reference
                  </li>
                  <li
                    onClick={() => {
                      setLeadSource("What's App Farming");
                    }}
                  >
                    What's App Farming
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="rightDiv_AddLead">
            <label htmlFor="newLeadName">City*</label>
            <input
              onChange={(e) => {
                setCity(e.target.value);
              }}
              placeholder="Enter City"
              type="text"
              className="newLead_Name"
            />{" "}
            <label htmlFor="newLeadName">State*</label>
            <input
              onClick={() => {
                if (statePicklistClass === "state_picklist_newLead_disabled") {
                  setStatePicklistClass("state_picklist_newLead");
                } else {
                  setStatePicklistClass("state_picklist_newLead_disabled");
                }
              }}
              placeholder={state}
              type="text"
              className="newLead_Name"
            />
            <div className={statePicklistClass}>
              <ul>
                {Cou_States.map((state) => {
                  return (
                    <>
                      <li
                        onClick={() => {
                          setState(state.state);
                          setStatePicklistClass(
                            "state_picklist_newLead_disabled"
                          );
                        }}
                      >
                        {state.state}
                      </li>
                    </>
                  );
                })}
              </ul>
            </div>
            <label htmlFor="newLeadName">Country</label>
            <input
              // placeholder="Enter Country"
              placeholder={country}
              type="text"
              className="newLead_Name"
            />{" "}
            <label htmlFor="newLeadName">Pincode</label>
            <input
              onChange={(e) => {
                setPincode(e.target.value);
              }}
              placeholder="Enter Pincode"
              type="text"
              className="newLead_Name"
            />{" "}
            <label htmlFor="newLeadName">Notes</label>
            <textarea
              onChange={(e) => {
                setNotes(e.target.value);
              }}
              placeholder="Enter Notes"
              type="text"
              className="newLead_Name"
            />{" "}
          </div>
        </div>
        <div className="addLeadbuttonsDiv">
          <button
            onClick={() => {
              if (newLeadDialogBox === "NewLeadDialogBox_Close") {
                setNewLeadDialogBox("NewLeadDialogBox_Open");
                setLeadsSectionClass("LeadsSection_disabled");
                console.log(newLeadDialogBox);
                return;
              } else {
                setNewLeadDialogBox("NewLeadDialogBox_Close");
                console.log(newLeadDialogBox);
                setLeadsSectionClass("LeadsSection");
                inputTag[0].value = "";
                inputTag[1].value = "";
                inputTag[2].value = "";
                inputTag[3].value = "";
                inputTag[4].value = "";
                inputTag[5].value = "";
                inputTag[6].value = "";
                inputTag[7].value = "";
                // inputTag[8].value = "";
                // inputTag[9].value = "";
                // inputTag[10].value = "";
                // inputTag[11].value = "";

                setLeadName("");
                setMobile("");
                setLeadSource("None");
                setLeadStatus("None");
                setDate("");
                setProductReq("None");
                setProductSize("");
                setCity("");
                setState("");
                setCountry("");
                setPincode("");
                setNotes("");
                return;
              }
            }}
            className="CancleButtonClass"
          >
            Cancle
          </button>

          <button onClick={handleSubmit} className="SaveButtonClass">
            Save
          </button>
        </div>
      </section>
    </>
  );
}
