import React, { useEffect, useState } from "react";
import "./dashboard.css";

export default function Dashboard() {
  const [currentDate, setCurrentDate] = useState("");
  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toLocaleDateString("en-US", {
      weekday: "long", // Saturday
      year: "numeric", // 2024
      month: "long", // November
      day: "numeric", // 9
    });
    setCurrentDate(formattedDate);

    // Get the current hour
    const currentHour = today.getHours();

    // Determine the greeting based on the time of day
    if (currentHour < 12) {
      setGreeting("Good morning");
    } else if (currentHour < 17) {
      setGreeting("Good afternoon");
    } else {
      setGreeting("Good evening");
    }
  }, []);

  return (
    <>
      <section className="mainSection_dash">
        <div className="mainDiv_dash">
          <h3>Home</h3>
          <span>{currentDate}</span>
          <h2>{greeting}, Arun</h2>

          <div className="todaysDueTasks_Div">
            <p>This is Due Task</p>
          </div>
          <div className="leadsByStatus">
            <p>Leads by Status</p>
          </div>
          <div className="dealsByStage">
            <p>Deals by Stage</p>
          </div>
          <div className="leadsBySource">
            <p>Leads by Source</p>
          </div>
          <div className="leadsByGeography">
            <p>Leads by Geography</p>
          </div>
        </div>
      </section>
    </>
  );
}
