import React, { useState } from "react";
import "./signInPage.css";
// import bgImage from "../Images/loginFormImage.jpg";
import logo from "../Assets/mainLogo.png";
import { Link } from "react-router-dom";
export default function SignInPage() {
  const [userName, setUserName] = useState();
  const [userPass, setPassword] = useState();

  // eslint-disable-next-line
  // eslint-disable-next-line

  // const userLogIn_Name = localStorage.getItem("userName");
  // const userLogIn_Pass = localStorage.getItem("userPass");

  return (
    <>
      <section className="SignInPage_MainSection">
        <div className="loginForm">
          {" "}
          {/* <div className="leftDiv_imgDiv_SignInSection">
            <img src={bgImage} alt="Login Form x360Marketing CRM" />
          </div> */}
          <div className="rightDiv_formDiv_SignInSection">
            <div className="icon_Div_Logo">
              <img src={logo} alt="x360 Marketing Logo Icon" />
              <h1>Welcome to <span style={{color:"#f34100"}}>x360 </span>CRM Solution For You Business</h1>
              <p>Please login with your credentials for start adventure</p>
            </div>
            <div className="formDiv">
              <div className="inputFieldDiv">
                <label htmlFor="">Username</label>
                <input
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                  placeholder="Enter Username"
                  type="text"
                />
              </div>

              <div className="inputFieldDiv">
                <label htmlFor="">Password</label>
                <input
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  placeholder="Enter Password"
                  type="password"
                />
              </div>

              <div className="rememberMe_Div">
                <span>
                  {" "}
                  <input type="checkbox" />
                  <label htmlFor="">Remember Me</label>
                </span>

                <Link>Forgot Password?</Link>
              </div>

              <div className="buttonDiv_form">
                <button
                  onClick={() => {
                    var now = new Date().getTime();

                    if (
                      userName === "jitendra@machmart" &&
                      userPass === "machmart@0224"
                    ) {
                      localStorage.setItem("userName", userName);
                      alert("Login Successfull");
                      localStorage.setItem("setupTime", now);

                      localStorage.setItem("userPass", userPass);
                      window.location.href = "/";
                    } else {
                      alert("Invalid Data");
                    }
                  }}
                >
                  {" "}
                  Login
                </button>
              </div>
              <span className="newToPlatForm_span">
                New to our platform? <Link>Create an Account</Link>
              </span>
              <span style={{textAlign:"center",marginTop:"25px", color:"rgb(80,80,80)", fontSize:"12px", fontWeight:"300"}}>

                Thi0s site is proctected by reCAPTCHA and the Google <span style={{textDecoration:"underline"}}>Privacy Policy</span> and <span style={{textDecoration:"underline"}}>Terms of Service</span> apply. 
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
