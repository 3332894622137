import React from "react";
import "./topbar.css";
import ownImg from "../Images/ownImg.jpeg";
import { Link } from "react-router-dom";

export default function NavigationTop() {
  return (
    <>
      <sectionl className="mainSectionTopNav">
        <div className="createBTN">
          <i className="fa-solid fa-circle-plus fa-lg"></i>
          <span>Create</span>
        </div>
        <div className="searchBar_topbar">Search Bar</div>
        <div className="accountDet_topbar">
          <i className="fa-solid fa-question" />
          <Link
            onClick={() => {
              localStorage.removeItem("userName");
              localStorage.removeItem("userPass");
              alert("Log Out Successfull");
              window.location.href = "/";
            }}
          >
            {" "}
            <img src={ownImg} alt="User Profile" height={"35px"} />
            <i className="fa-solid fa-angle-down fa-sm"></i>
          </Link>
        </div>
      </sectionl>
    </>
  );
}
