// Import necessary dependencies and styles
import React from "react";
import "./navnew.css";
import { Link } from "react-router-dom";

// Functional component for NavigationBar
export default function NavigationBar() {
  return (
    <section>
      {/* Main navigation div */}
      <div className="mainDiv_Navbar">
        
        {/* Main Navigation Links */}
        <ul>
          <li>
            {/* Link to Dashboard */}
            <Link to={"/"}>
              <i className="fa-solid fa-house"></i>Home
            </Link>
          </li>
          <li>
            {/* Link to Leads */}
            <Link to={"/leads"}>
              <i className="fa-solid fa-user"></i>Leads
            </Link>
          </li>
          <li>
            {/* Link to Deals */}
            <Link to={"/deals"}>
              <i className="fa fa-tag"></i>Deals
            </Link>
          </li>
          <li>
            {/* Link to Contacts */}
            <Link to={"/contacts"}>
              <i className="fa-solid fa-address-book"></i>Contact
            </Link>
          </li>
          <li>
            {/* Link to Tasks */}
            <Link to={"/tasks"}>
              <i className="fa-solid fa-list-check"></i>Tasks
            </Link>
          </li>
        </ul>
        
        {/* Divider */}
        <div className="dvd"></div>

        {/* Marketing Tools Section */}
        <ul>
          <span>Marketing Tools</span>
          <li>
            {/* Link to Website Builder */}
            <Link>
              <i className="fa fa-globe"></i>Website Builder
            </Link>
          </li>
          <li>
            {/* Link to Blogs Management */}
            <Link>
              <i className="fa fa-pen"></i>Blogs Management
            </Link>
          </li>
          <li>
            {/* Link to Social Media Posts */}
            <Link>
              <i className="fa fa-share-alt"></i>Social Media Posts
            </Link>
          </li>
        </ul>

        {/* Divider */}
        <div className="dvd"></div>

        {/* Insights & Settings Section */}
        <ul>
          <span>Insights & Settings</span>
          <li>
            {/* Link to Integration */}
            <Link>
              <i className="fa fa-plug"></i>Integration
            </Link>
          </li>
          <li>
            {/* Link to Reports & Analytics */}
            <Link>
              <i className="fa fa-chart-line"></i>Reports & Analytics
            </Link>
          </li>
          <li>
            {/* Link to Settings & Profiles */}
            <Link>
              <i className="fa fa-user-cog"></i>Settings & Profiles
            </Link>
          </li>
        </ul>

        {/* Plan Details Section */}
        <div className="planDet">
          <span>Plan Details:</span>
          <span><span style={{color:"orangered"}}>Advanced</span> Full Access</span>
        </div>
      </div>
    </section>
  );
}
